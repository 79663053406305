<template>
  <div>
      <v-data-table
        :headers="headers"
        :items="displayDocuments"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        expand-icon="$sort"
        hide-default-footer
        show-expand
        single-expand
      >
        <template v-slot:item.type="{ item }">
          {{ item.type | getDescription(documentTypes) }}
        </template>
        <template v-slot:item.validTo="{ item }">
          {{ item.validTo | dateFormat("DD.MM.YYYY") }}
        </template>
        <template v-slot:item.dateOfDeprecation="{ item }">
          {{ item.dateOfDeprecation | dateFormat("DD.MM.YYYY") }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td class="py-4" :colspan="headers.length">
            <div class="d-flex flex-column ml-6">
              <div v-for="file in item.files" :key="file.name"  class="d-flex align-center mb-2">
                <v-icon class="mr-4 pointer" small @click="getFile(file)" >$download</v-icon>
                <h4>{{ file.name }}</h4>
              </div>
          </div>
          </td>
        </template>
      </v-data-table>
      <v-pagination
        v-if="pageCount > 1"
        :value="options.page"
        :length="pageCount"
        @input="options.page = $event"
      ></v-pagination>
  </div>
</template>
<script>
import Service from '@/services'
import { downloadFile } from '@/utils/helpers'

export default {
  name: 'Archive',
  props: {
    isMyCompanyRoute: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      total: 0,
      options: {},
      headers: [
        {
          text: this.$t('companiesDetails.type'),
          align: 'start',
          value: 'type',
          sortable: false
        },
        {
          text: this.$t('companiesDetails.date'),
          value: 'validTo',
          sortable: false
        },
        {
          text: this.$t('companiesDetails.name'),
          value: 'description',
          sortable: false
        },
        {
          text: this.$t('companiesDetails.user'),
          value: 'name',
          sortable: false
        },
        {
          text: this.$t('companiesDetails.deprecation'),
          value: 'dateOfDeprecation',
          sortable: false
        }
      ],
      documents: []
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.total / this.options.itemsPerPage)
    },
    documentTypes () {
      return this.$store.getters.enums.documentTypes
    },
    usersState () {
      return this.$store.getters['users/users']
    },
    displayDocuments () {
      return this.documents.map((document) => {
        const u = this.usersState.find(
          (item) => item.id === document.depreciatedBy
        )
        if (!u) {
          return document
        }

        document.name = `${u.name} ${u.surname}`

        return document
      })
    }
  },
  watch: {
    options: {
      handler () {
        this.getDocuments()
      },
      deep: true
    }
  },
  methods: {
    async getDocuments () {
      this.loading = true
      const { page, itemsPerPage } = this.options

      try {
        const { data } = await Service.getList(
          `Company/GetArchivedCompanyDocuments?companyId=${this.$store.getters.userCommonData.companyId}`,
          page,
          itemsPerPage
        )
        this.documents = data.results
        this.total = data.count
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getFile (file) {
      try {
        const { data } = await Service.getFile(
          '/Company/GetCompanyDocumentFile',
          {
            companyId: this.$store.getters.userCommonData.companyId,
            fileName: file.name
          }
        )

        downloadFile(file, data)
      } catch (e) {}
    }
  }
}
</script>
<style scoped>
</style>
