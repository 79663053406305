<template>
<div v-if="summary">
  <v-card-text>
    <span class="body-1">{{ $t('reviews.rate') }}</span>
    <div class="d-flex align-center mb-4 mt-1">
      <span class="font-weight-bold primary--text mr-2 pt-1">{{ summary.generalRate.average.toFixed(1) }}</span>
      <v-rating length="5" size="16" color="accent" readonly  :value="+summary.generalRate.average" class="mr-2" />
      <span class="pt-1">{{ summary.generalRate.reviewsCount }} {{ $t('reviews.grades') }} / {{ summary.generalRate.companiesReviewedCount }}  {{ $t('reviews.companies') }}</span>
    </div>
    <span class="body-1">{{ $t('reviews.rates') }}</span>
    <div class="mt-1" style="width: 350px">
      <v-row v-for="(item, name) in summary" :key="name" no-gutters>
        <template v-if="item.average > 0">
          <v-col cols="5">
            <span class="body-2">{{ $t(`reviews.${name}`) }}</span>
          </v-col>
          <v-col cols="4" align-self="end">
            <v-rating length="5" size="16" color="accent" readonly :value="+item.average" class="mb-1 d-flex justify-end" />
          </v-col>
          <v-col cols="3">
            <span class="font-weight-bold primary--text ml-2">{{ item.average.toFixed(1) }}</span>
          </v-col>
        </template>
      </v-row>
    </div>
  </v-card-text>
  <v-divider />
  <v-card-text>
    <v-form ref="form">
      <v-row align="center">
        <v-col cols="12" md="3">
          <label>{{ $t('reviews.place') }}</label>
          <v-text-field v-model="searchInputValue" prepend-inner-icon="$magnify" outlined dense single-line hide-details="auto" />
        </v-col>
        <v-col cols="12" md="2">
          <DatePicker v-model="params.dateFrom" :label="$t('filters.dateFrom')" />
        </v-col>
        <v-col cols="12" md="2">
          <DatePicker v-model="params.dateTo" :label="$t('filters.dateTo')" />
        </v-col>
        <v-col cols="12" md="2">
          <label>{{ $t('reviews.state') }}</label>
          <v-select
            v-model="params.side"
            :items="sideOfTransaction"
            item-value="id"
            item-text="description"
            class="text-left"
            :placeholder="`${$t('common.select')}...`"
            outlined
            dense
            clearable
            hide-details
          />
        </v-col>
       </v-row>
    </v-form>
  </v-card-text>
  <v-divider />
  <data-table
    ref="dataTable"
    :headers="headers"
    :endpoint="`Reviews/GetCompanyReviews`"
    :additionalParams="{ dateFrom: params.dateFrom, dateTo: params.dateTo, companyId: $route.params.id, sideOfTransaction: params.side }"
    serverPagination
  >
    <template v-slot:item.sideOfTransaction="{ item }">
      <div v-if="item.sideOfTransaction === 1" class="d-flex align-center">
        <v-icon size="26">$provider</v-icon>
        <div class="ml-4">
          {{ $t('reviews.provider') }}
        </div>
      </div>
      <div v-else class="d-flex align-center">
        <v-icon size="26">$client</v-icon>
        <div class="ml-4">
          {{ $t('reviews.client') }}
        </div>
      </div>
    </template>
    <template v-slot:[`item.review`]="{ item }">
      <v-tooltip v-if="item.review" bottom min-width="310" max-width="310">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="d-flex align-center">
            <span class="font-weight-bold primary--text mr-2 pt-1">{{ item.review.generalRate.toFixed(1) }}</span>
            <v-rating length="5" size="16" color="accent" readonly :value="+item.review.generalRate" class="mr-2" />
          </div>
          <span v-if="item.review" class="text">{{ item.review.comment | trimText(25) }}</span>
        </template>
        <v-row class="py-4">
          <v-col cols="6">
            <div class="body-2 mb-2">{{ $t('reviews.comment') }}</div>
            <div class="comment body-2">
              {{ item.review.comment | trimText(250) }}
            </div>
          </v-col>
          <v-col cols="6">
            <div class="body-2 mb-2">{{ $t('reviews.title') }}</div>
            <div v-for="(rate, name) in item.review" :key="name">
              <div v-if="rate && (name !== 'generalRate' && name !== 'comment')" class="body-2 mb-2">
                {{ $t(`reviews.${name}`) }}
                <v-rating length="5" size="16" color="accent" readonly :value="+rate" />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-tooltip>
      <div v-else>
        <span v-if="item.reviewDeleted">{{ $t('reviews.removed') }}</span>
        <span v-else>{{ $t('reviews.none') }}</span>
      </div>
    </template>
    <template v-slot:item.reviewDate="{ item }">
      <div class="text-center">
        {{ item.reviewDate | dateFormat('DD.MM.YYYY') }} <br/>
        {{ item.reviewDate | dateFormat('HH:mm') }}
      </div>
    </template>
    <template v-slot:item.contractor="{ item }">
      <div v-if="item.contractor" class="d-flex align-center cursor-pointer">
        <StatusAvatar :userId="item.contractor.id" :size="26" showStatus class="mr-2"></StatusAvatar>
        <div class="d-flex flex-column">
        <div>{{ item.contractor.name }} {{ item.contractor.surname }}</div>
          <v-tooltip bottom min-width="320px">
            <template v-slot:activator="{ on, attrs }">
            <div class="lightText--text" v-bind="attrs" v-on="on">{{ item.contractor.company.name | trimText(20) }}</div>
            </template>
            <v-row class="mt-1">
              <v-col cols="6">
                <p>{{ item.contractor.company.name }}<br />
                  NIP: {{ item.contractor.company.vatNumber }}</p>
                <p>{{ $t('offerDetails.address') }}:<br />
                {{ item.contractor.company.street }}<br />
                {{ item.contractor.company.zipCode }}, {{ item.contractor.company.city }}</p>
              </v-col>
              <v-col cols="6">
                {{ $t('offerDetails.averageRating') }}:
                <v-rating length="5" size="16" color="accent" :value="+item.contractor.company.averageRating" class="mb-4"></v-rating>
                  {{ $t('offerDetails.paymentRating') }}:
                <v-rating length="5" size="16" color="accent" :value="+item.contractor.company.averagePaymentRating"></v-rating>
              </v-col>
            </v-row>
          </v-tooltip>
        </div>
      </div>
    </template>
    <template v-slot:no-data>
      {{ $t("rating.noData") }}
    </template>
  </data-table>
</div>
</template>

<script>
import Service from '@/services'
import DataTable from '@/components/DataTable'
import StatusAvatar from '@/components/StatusAvatar'
import DatePicker from '@/components/forms/DatePicker'

export default {
  name: 'RatingsList',
  props: {
    isMyCompanyRoute: {
      type: Boolean,
      required: true
    }
  },
  components: {
    DataTable,
    StatusAvatar,
    DatePicker
  },
  data () {
    return {
      params: {
        dateFrom: null,
        dateTo: null,
        side: null
      },
      summary: null,
      searchInputValue: '',
      sideOfTransaction: [
        { id: 1, description: this.$t('reviews.provider') },
        { id: 2, description: this.$t('reviews.client') }
      ],
      headers: [
        {
          text: this.$t('reviews.state'),
          value: 'sideOfTransaction',
          sortable: false
        },
        {
          text: this.$t('reviews.tabRate'),
          sortable: false,
          value: 'review'
        },
        {
          text: this.$t('reviews.created'),
          align: 'center',
          value: 'reviewDate'
        },
        {
          text: this.$t('reviews.contractor'),
          align: 'start',
          sortable: false,
          value: 'contractor'
        }
      ]
    }
  },
  async created () {
    await this.getDetails()
  },
  methods: {
    async getDetails () {
      this.loading = true

      try {
        const { data } = await Service.get(`/Reviews/GetReviewsSummary?companyId=${this.$route.params.id}`)
        this.summary = data
      } catch (e) {}

      this.loading = false
    }
  }
}
</script>
<style>
.comment {
  width: 100%;
  padding: 8px 4px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
</style>
