<template>
  <v-card flat>
    <v-form v-model="valid" ref="updateForm" :disabled="submitLoading">
      <label>{{ $t('companiesDetails.name') }}</label>
      <v-text-field v-model="document.description" outlined dense :rules="required" :disabled="document.verified || !isMyCompanyRoute" />
      <v-radio-group v-if="isMyCompanyRoute" v-model="validOptions" class="w-50" :disabled="document.verified">
        <v-radio :value="0">
          <template v-slot:label>
            <span>{{ $t('companiesDetails.indefinitely') }}</span>
          </template>
        </v-radio>
        <v-radio :value="1">
          <template v-slot:label>
            <v-menu v-model="validToMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="validTo" label="data" append-icon="$calendar" readonly dense outlined single-line hide-details :disabled="validIndefinitely() || validityNotApplicable() || document.verified" v-bind="attrs" v-on="on"/>
              </template>
              <v-date-picker
                v-model="validTo"
                :first-day-of-week="1"
                @input="validToMenu = false"
              ></v-date-picker>
            </v-menu>
          </template>
        </v-radio>
        <v-radio v-if="document.type === 4" :value="2">
          <template v-slot:label>
            <span>{{ $t('companiesDetails.notApplicable') }}</span>
          </template>
        </v-radio>
      </v-radio-group>
      <div class="d-flex flex-column">
        <div v-for="file in document.files" :key="file.name" class="d-flex align-center mb-2">
          <h4>{{ fileName(file.name) }}</h4>
          <v-icon class="ml-4 pointer" small @click="getFile(file)">$download</v-icon>
        </div>
        <div>
          <v-btn v-if="isMyCompanyRoute" color="primary" :disabled="loading || document.verified" @click="removeDocument" outlined>
            {{ $t('companiesDetails.delete') }}
          </v-btn>
        </div>
      </div>
      <v-checkbox v-model="document.verified" hide-details disabled>
        <template v-slot:label>
          <div class="d-flex flex-column">
            <span>{{ $t('companiesDetails.verified') }}</span>
            <small v-if="document.verified">
              {{ document.dateOfVerification | dateFormat("DD.MM.YYYY") }}
              {{ document.name }} {{ document.surname }}
            </small>
          </div>
        </template>
      </v-checkbox>
      <div class="d-flex">
        <v-spacer></v-spacer>
        <v-btn v-if="isMyCompanyRoute" color="primary" outlined :disabled="loading || document.verified" :loading="loading" @click="updateDocument(document)">
          {{ $t('companiesDetails.updateDoc') }}
        </v-btn>
      </div>
    </v-form>
  </v-card>
</template>
<script>
import Service from '@/services'
import { dateFormat2, downloadFile } from '@/utils/helpers.js'

export default {
  name: 'DocumentItem',
  props: ['document', 'loading', 'isMyCompanyRoute'],
  data () {
    return {
      validToMenu: false,
      valid: true,
      submitLoading: false,
      required: [
        (v) => (!!v && !!v.trim()) || this.$t('validation.fieldRequired')
      ],
      validOptions: 0
    }
  },
  computed: {
    validTo: {
      get () {
        return dateFormat2(this.document.validTo)
      },
      set (newValue) {
        this.document.validTo = newValue
      }
    }
  },
  watch: {
    validOptions: function (val) {
      if (val) {
        this.document.notificationMothBefore = false
        this.document.notificationWeekBefore = false
        this.document.notificationDayBefore = false
      }
    }
  },
  methods: {
    fileName (name) {
      var length = name.length
      if (length > 40) {
        return name.slice(0, 20) + '...' + name.slice(length - 10, length)
      }
      return name
    },
    updateDocument (document) {
      this.$refs.updateForm.validate()
      if (!this.valid) {
        return
      }
      this.$emit('update', {
        document: {
          id: document.id,
          description: document.description,
          validIndefinitely: this.validIndefinitely(),
          validTo: document.validTo
        }
      })
    },
    removeDocument () {
      this.$emit('remove', this.document.id)
    },
    submit () {
      this.$refs.updateCommentForm.validate()
      if (!this.valid) {
        return
      }
      this.$emit('submit')
      this.$refs.updateCommentForm.resetValidation()
    },
    async getFile (file) {
      try {
        const { data } = await Service.getFile('/Company/GetCompanyDocumentFile', {
          companyId: this.$store.getters.userCommonData.companyId,
          fileName: file.name
        })

        downloadFile(file, data)
      } catch (e) {}
    },
    validIndefinitely () {
      return this.validOptions === 0
    },
    validityNotApplicable () {
      return this.validOptions === 2
    }
  },
  mounted () {
    if (this.document.validIndefinitely) {
      this.validOptions = 0
    } else if (this.document.validityNotApplicable) {
      this.validOptions = 1
    } else {
      this.validOptions = 2
    }
  }
}
</script>
