<template>
  <div>
    <v-card-text>
    <v-row v-if="details">
      <v-col cols="12" md="6">
        <v-card class="h-100" outlined flat>
          <v-card-text class="pa-4">
          <v-row>
            <v-col cols="4">
              <div v-if="details.logo && details.logo != ''" class="d-flex mb-2">
                <v-avatar rounded class="avatar-responsive">
                  <img :src="getAvatar(details.logo)" :alt="details.companyName" />
                </v-avatar>
              </div>
              <div v-else class="mb-2">
                <div class="avatar rounded"></div>
              </div>
              <UploadFileButton
                v-if="isMyCompanyRoute"
                :label="$t('companiesDetails.changeLogo')"
                :multiple="false"
                :showFileList="false"
                :items="files"
                outlined
                accept="image/*"
                @input="uploadLogo"
              />
            </v-col>
            <v-col cols="8">
            <v-col>
              <label> {{ $t('companiesDetails.companyId') }} </label>
              <v-text-field :value="details.displayId" outlined dense disabled />
            </v-col>
            <v-col >
              <div class="d-flex">
                <label>{{ $t('companiesDetails.payerStatus') }}</label>
                <v-hover v-if="details.payerStatus !== 0" v-slot="{ hover }" class="ml-1">
                  <div style="position: relative">
                    <v-icon>mdi-information</v-icon>
                    <v-card v-if="hover" style="position: absolute; right: 50%; transform:translateX(50%); z-index:999" elevation="6" min-width="300px" >
                      <v-card-text>
                        {{ $t('companiesDetails.payerStatusInfo')}}
                      </v-card-text>
                    </v-card>
                  </div>
                </v-hover>
              </div>
              <v-select
                v-model="details.payerStatus"
                :items="companyPayerStatuses"
                item-value="id"
                item-text="description"
                class="text-left"
                outlined
                dense
                :hint="dateOfPayerStatusChange"
                persistent-hint
                :disabled="!($store.getters.isAdmin || $store.getters.isCustomerService) || !$permission('CompanyChangePayerStatus')"
                @change="changePayerStatus"
              />
              <div v-if="!isMyCompanyRoute && !isCooperationStatusDisabled">
                <label>{{ $t('companiesDetails.cooperationStatus') }}</label>
                <v-select
                  v-model="details.cooperationStatus"
                  :items="cooperationStatuses"
                  item-value="id"
                  item-text="description"
                  class="text-left"
                  outlined
                  dense
                  persistent-hint
                  :disabled="isCooperationStatusDisabled"
                  @change="changeCooperationStatus"
                  />
              </div>
            </v-col>
            </v-col>
          </v-row>
          <h3 class="primary--text pt-8 pb-2">{{ $t('companiesDetails.contact') }}</h3>
          <v-row class="py-2">
            <v-col cols="6" class="py-1">
              <label>{{ $t('companiesDetails.ownerName') }}</label>
              <v-text-field :value="details.userName" outlined dense hide-details :readonly="true" />
            </v-col>
            <v-col cols="6" class="py-1">
              <label>{{ $t('companiesDetails.surname') }}</label>
              <v-text-field :value="details.surname" outlined dense hide-details :readonly="true" />
            </v-col>
            <v-col cols="6" class="py-1">
              <label>{{ $t('companiesDetails.ownerPhone') }}</label>
              <v-text-field :value="details.phone" outlined dense hide-details :readonly="true" />
            </v-col>
            <v-col cols="6" class="py-1">
              <label>{{ $t('companiesDetails.email') }}</label>
              <v-text-field :value="details.email" outlined dense hide-details :readonly="true" />
            </v-col>
          </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="h-100" outlined flat>
          <v-card-text class="pa-4">
          <h3 class="primary--text mb-2">
            {{ $t('companiesDetails.header') }}
          </h3>
          <v-chip v-if="companyDataChangesRequested && isMyCompanyRoute" class="mb-4 mt-2" color="primary" small>
            {{ $t('companiesDetails.dataSentToVerification') }}
          </v-chip>
          <v-form ref="updateProfileForm" v-model="valid" :readonly="submitLoading">
            <v-row class="pt-2">
              <v-col cols="12" class="py-1">
                <label>{{ $t('companiesDetails.companyName') }}</label>
                <v-text-field v-model="details.companyName" hide-details outlined dense :readonly="companyDataChangesRequested || !isMyCompanyRoute" :rules="required" />
              </v-col>
              <v-col cols="6" class="py-1">
                <label>{{ $t('companiesDetails.taxNumber') }}</label>
                <v-text-field v-model="details.vatNumber" hide-details outlined dense :readonly="companyDataChangesRequested || !isMyCompanyRoute" :rules="required" />
              </v-col>
              <v-col cols="6" class="py-1">
                <label>{{ $t('companiesDetails.street') }}</label>
                <v-text-field v-model="details.street" hide-details  outlined dense :readonly="companyDataChangesRequested || !isMyCompanyRoute" :rules="required" />
              </v-col>
              <v-col cols="6" class="py-1">
                <label>{{ $t('companiesDetails.zipCode') }}</label>
                <v-text-field v-model="details.zipCode" hide-details  outlined dense :readonly="companyDataChangesRequested || !isMyCompanyRoute" :rules="[...required, maxCharInZipCode]" />
              </v-col>
              <v-col cols="6" class="py-1">
                <label>{{ $t('companiesDetails.city') }}</label>
                <v-text-field v-model="details.city" hide-details outlined dense :readonly="companyDataChangesRequested || !isMyCompanyRoute" :rules="required" />
              </v-col>
              <v-col cols="6" class="py-1">
                <label>{{ $t('companiesDetails.country') }}</label>
                <v-select v-model="details.country" :items="countries" :readonly="companyDataChangesRequested || !isMyCompanyRoute" outlined dense single-line item-text="label" item-value="code" />
              </v-col>
            </v-row>
            <div class="d-flex">
              <v-spacer></v-spacer>
              <v-btn v-if="isMyCompanyRoute && !companyDataChangesRequested" :disabled="!companyDetailsChanged" outlined @click="updateDetails" color="primary" >
                {{ $t('companiesDetails.sendRequest') }}
              </v-btn>
            </div>
          </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    </v-card-text>
    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
  </div>
</template>
<script>
import Service from '@/services'
import { mapGetters } from 'vuex'
import { fileUrl, dateFormat, maxCharInZipCode } from '@/utils/helpers'
import UploadFileButton from '@/components/forms/UploadFileButton.vue'

export default {
  name: 'Information',
  data () {
    return {
      loading: false,
      details: null,
      valid: true,
      submitLoading: false,
      languages: [],
      files: [],
      lastSavedCompanyData: null,
      required: [
        (v) => (!!v && !!v.trim()) || this.$t('validation.fieldRequired')
      ],
      maxCharInZipCode
    }
  },
  components: {
    UploadFileButton
  },
  props: {
    isMyCompanyRoute: {
      type: Boolean,
      required: true
    }
  },
  async created () {
    await this.fetchLanguages()
    if (this.$store.getters.isAdmin || this.$store.getters.isCustomerService) {
      await this.fetchDataForCustomerService()
    } else {
      await this.fetchDataForUser()
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin',
      isCustomerService: 'isCustomerService'
    }),
    countries () {
      return this.$store.getters.countries
    },
    companyPayerStatuses () {
      return this.$store.getters.enums.companyPayerStatus
    },
    cooperationStatuses () {
      return this.$store.getters.enums.companyCooperationStatus
    },
    dateOfPayerStatusChange () {
      if (this.details.dateOfPayerStatusChange) {
        return `${this.$t('companiesDetails.dateOfPayerStatusChange')}
        ${dateFormat(this.details.dateOfPayerStatusChange)} `
      } else {
        return ''
      }
    },
    companyDetailsChanged () {
      for (const prop in this.lastSavedCompanyData) {
        if (this.lastSavedCompanyData[prop] !== this.details[prop]) {
          return true
        }
      }
      return false
    },
    companyDataChangesRequested () {
      return !!this.details.companyChanges
    },
    isCooperationStatusDisabled () {
      if (this.$store.getters.isOwner && (this.$store.getters.userCommonData.companyId !== this.$route.params.id)) {
        return false
      }

      return true
    }
  },
  methods: {
    async fetchDataForUser () {
      this.loading = true
      try {
        const companyId = this.isMyCompanyRoute ? this.$store.getters.userCommonData.companyId : this.$route.params.id
        const { data } = await Service.get('/Company/GetCompanyDetails', { companyId: companyId })

        this.details = data
        this.lastSavedCompanyData = {
          vatNumber: data.vatNumber,
          companyName: data.companyName,
          street: data.street,
          zipCode: data.zipCode,
          city: data.city,
          country: data.country
        }

        this.$emit('isIntegrationApiEnabledUpdate', data.isIntegrationApiEnabled)
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    getAvatar (url) {
      return fileUrl(url)
    },
    async fetchDataForCustomerService () {
      this.loading = true

      const companyId = this.isMyCompanyRoute ? this.$store.getters.userCommonData.companyId : this.$route.params.id

      try {
        const { data } = await Service.get('/Company/GetCompanyDetails', {
          companyId: companyId
        })
        this.details = data
      } catch (e) {}

      this.loading = false
    },
    async fetchLanguages () {
      const { data } = await Service.get('Frontend/GetAvailableLanguages')
      this.lang = data
      this.languages = data.map(language => {
        return {
          value: language,
          label: this.$t(`languages.${language}`)
        }
      })
    },
    async updateDetails () {
      this.$refs.updateProfileForm.validate()
      if (!this.valid) {
        return
      }
      this.submitLoading = true

      try {
        await Service.post('Company/RequestCompanyUpdate', {
          companyId: this.isMyCompanyRoute ? this.$store.getters.userCommonData.companyId : this.$route.params.id,
          vatNumber: this.details.vatNumber,
          name: this.details.companyName,
          street: this.details.street,
          zipCode: this.details.zipCode,
          city: this.details.city,
          country: this.details.country
        })
        this.$refs.updateProfileForm.resetValidation()
        await this.$store.dispatch('setSuccessNotification', this.$t('companiesDetails.updateProfile')
        )
        this.fetchDataForUser()
      } catch (e) {}
      this.submitLoading = false
    },
    async changePayerStatus () {
      try {
        await Service.put('CustomerService/ChangePayerStatus', {
          companyId: this.$route.params.id,
          payerStatus: this.details.payerStatus
        })

        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
        await this.fetchDataForCustomerService()
      } catch (e) {}
    },
    async changeCooperationStatus () {
      try {
        await Service.post('Company/SetCompanyCooperationStatus', {
          companyId: this.$route.params.id,
          cooperationStatus: this.details.cooperationStatus
        })
        await this.fetchDataForUser()
      } catch (e) {}
    },
    async markDebtCollectionProcess () {
      try {
        await Service.put('CustomerService/MarkDebtCollectionProcess', {
          companyId: this.$route.params.id,
          inDebtCollectionProcess: this.details.inDebtCollectionProcess
        })

        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
        await this.fetchDataForCustomerService()
      } catch (e) {}
    },
    async uploadLogo (files) {
      const formData = new FormData()
      formData.append('file', files[0])
      try {
        await Service.post('Company/AddCompanyLogo', formData)
        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
        await this.fetchDataForUser()
      } catch (e) {
      } finally {
        this.files = []
      }
    }
  }
}
</script>

<style scoped>
.v-messages {
  text-align: left !important;
}
.avatar {
  width: 100px;
  height: 100px;
  border: solid thin  var(--v-primary-base);
  background-color: var(--v-background-base);
}
</style>
