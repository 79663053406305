var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.displayDocuments,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"expand-icon":"$sort","hide-default-footer":"","show-expand":"","single-expand":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getDescription")(item.type,_vm.documentTypes))+" ")]}},{key:"item.validTo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.validTo,"DD.MM.YYYY"))+" ")]}},{key:"item.dateOfDeprecation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.dateOfDeprecation,"DD.MM.YYYY"))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-4",attrs:{"colspan":headers.length}},[_c('div',{staticClass:"d-flex flex-column ml-6"},_vm._l((item.files),function(file){return _c('div',{key:file.name,staticClass:"d-flex align-center mb-2"},[_c('v-icon',{staticClass:"mr-4 pointer",attrs:{"small":""},on:{"click":function($event){return _vm.getFile(file)}}},[_vm._v("$download")]),_c('h4',[_vm._v(_vm._s(file.name))])],1)}),0)])]}}])}),(_vm.pageCount > 1)?_c('v-pagination',{attrs:{"value":_vm.options.page,"length":_vm.pageCount},on:{"input":function($event){_vm.options.page = $event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }