<template>
<div id="document">
  <v-btn v-if="isMyCompanyRoute" color="primary" class="my-2" :disabled="addNew || !$store.getters.isManagerOrOwner" @click="addNew = !addNew" outlined>
    {{ $t('companiesDetails.addDoc') }}
  </v-btn>
  <v-row no-gutters>
    <v-col cols="12" lg="7" xl="6">
      <v-expansion-panels v-if="addNew" v-model="newDocumentPanel" multiple flat class="justify-start" active-class="my-2">
        <v-expansion-panel readonly class="outlined">
          <v-expansion-panel-header expand-icon="" />
          <v-expansion-panel-content>
            <NewDocument
              :type="documentType"
              :loading="loading"
              @submit="submitHandler"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-col cols="12" lg="7" xl="6">
      <v-expansion-panels v-model="documentPanel" flat multiple active-class="my-2">
        <v-expansion-panel v-model="documentPanel" v-for="document in displayDocuments" :key="document.id" class="outlined">
          <v-expansion-panel-header class="font-weight-bold" expand-icon="$sort">
            <h4 class="ml-4 primary--text">{{ document.description }}</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <DocumentItem
              class=""
              :document="document"
              :loading="loading"
              :isMyCompanyRoute="isMyCompanyRoute"
              @update="documentUpdate"
              @remove="documentRemove"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
  <v-progress-linear v-if="loading" indeterminate />
</div>
</template>
<script>
import Service from '@/services'
import NewDocument from './DocumentNew.vue'
import DocumentItem from './DocumentItem.vue'

export default {
  name: 'DocumentsList',
  props: {
    documentType: {
      type: Number,
      required: true
    },
    isMyCompanyRoute: {
      type: Boolean,
      required: true
    }
  },
  components: {
    NewDocument,
    DocumentItem
  },
  data () {
    return {
      loading: true,
      addNew: false,
      documents: [],
      documentPanel: [0],
      newDocumentPanel: [0]
    }
  },
  computed: {
    usersState () {
      return this.$store.getters['users/users']
    },
    displayDocuments () {
      return this.documents.map((document) => {
        const u = this.usersState.find(
          (item) => item.id === document.verifiedBy
        )
        if (!u) {
          return document
        }

        document.name = u.name
        document.surname = u.surname

        return document
      })
    }
  },
  created () {
    this.getDocuments()
  },
  methods: {
    update () {
      this.$emit('update')
    },
    async getDocuments () {
      this.loading = true
      try {
        const { data } = await Service.get('/Company/GetMyCompanyDocuments', {
          companyId: this.$store.getters.userCommonData.companyId,
          documentType: this.documentType
        })
        this.documents = data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async documentUpdate (document) {
      this.loading = true
      try {
        await Service.put('/Company/UpdateDocument', document)
        await this.getDocuments()
        await this.$store.dispatch('setSuccessNotification', this.$t('companiesDetails.update'))
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async documentRemove (documentId) {
      this.loading = true

      try {
        await Service.put('/Company/ArchiveDocument', {
          documentId: documentId
        })
        await this.getDocuments()
        await this.$store.dispatch('setSuccessNotification', this.$t('companiesDetails.remove'))
        this.update()
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async submitHandler (document) {
      this.loading = true
      var formData = new FormData()

      for (const property in document) {
        if (property === 'files') {
          document[property].forEach(file => {
            formData.append('Document.FilesWrapper.Files', file, file.name)
          })
        } else {
          formData.append(`Document.${property}`, document[property])
        }
      }

      try {
        await Service.post('/Company/UploadDocument', formData)
        this.addNew = false
        await this.getDocuments()
        await this.$store.dispatch('setSuccessNotification', this.$t('companiesDetails.add'))
        this.update()
      } catch (e) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style>
#document .v-expansion-panel.outlined {
  border: thin solid var(--v-primary-base);
  border-bottom: none;
}
#document .v-expansion-panel--next-active,
#document .v-expansion-panel.outlined:last-child {
  border-bottom: thin solid var(--v-primary-base) !important;
}
#document .v-expansion-panel--active.outlined {
  border: thin solid var(--v-primary-base);
}
</style>
