<template>
  <div>
    <v-card-text>
      <v-row align="center">
        <v-col cols="12" md="3">
          <label>{{ $t('reviews.place') }}</label>
          <v-text-field v-model="searchInputValue" prepend-inner-icon="$magnify" outlined dense single-line hide-details="auto" />
        </v-col>
        <v-col cols="12" md="2" offset-md="7" class="d-flex justify-end">
          <v-btn v-if="isMyCompanyRoute" color="primary" depressed @click="openAddEmployeeDialog" class="ml-auto">
            {{ $t('users.dialogs.addNew.title') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <data-table
      ref="Employees"
      :headers="headers"
      endpoint="/Company/GetCompanyUsersWithPagination"
      :additionalParams="{ companyId }"
      :search="searchInputValue"
      serverPagination
    >
      <template v-slot:item.name="{ item }">
        {{ item.name }} {{ item.surname }}
      </template>
      <template v-slot:[`item.chat`]="{ item }">
        <v-btn v-if="currentUserId !== item.id" icon @click="startConversationWithUser(item.id)">
          <v-icon class="w-50" color="info">$conversation</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.role="{ item }">
        {{ getUserRole(item.role) }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          v-if="item.profileUpdated"
          class="ma-2"
          small
          label
          color="stateChange"
        >
          {{ $t("users.update") }}
        </v-chip>
        <v-chip v-else-if="item.status == 1" class="ma-2" small label color="active">
          {{ getUserStatus(item.status) }}
        </v-chip>
        <v-chip v-else class="ma-2" small label color="red">
          {{ getUserStatus(item.status) }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-menu bottom left>
          <template v-if="displayActionButton(item)" v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list v-if="displayActionOptions(item)" class="pa-0">
            <v-list-item v-if="item.status === 1 && !isOwner(item) && item.id !== $store.getters.userCommonData.id" link @click="deactivate(item.id)">
              <v-list-item-title>
                {{ $t('users.deactivate') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="item.status === 3 && !isOwner(item)  && item.id !== $store.getters.userCommonData.id" link @click="activate(item.id)">
              <v-list-item-title>
                {{ $t('users.active') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!isOwner(item) && (item.id !== $store.getters.userCommonData.id && ($store.getters.isOwner || $store.getters.isManager))"
            link @click="openSetRoleEmployeeDialog(item)">
              <v-list-item-title>
                {{ $t('users.dialogs.unblockUser.setRole.title')}}
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!isOwner(item) && (item.id !== $store.getters.userCommonData.id && ($store.getters.isOwner || $store.getters.isManager))"
            link @click="openEditDataDialog(item)">
              <v-list-item-title>
                {{ $t('users.dialogs.unblockUser.editData.title')}}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
                v-if="isIntegrationApiEnabled && ($store.getters.isOwner || $store.getters.isManager)"
                link
                @close="closeIntegrationApiTokenDialog"
                @click="openIntegrationApiTokenDialog(item)"
              >
                  <v-list-item-title>
                    {{ $t("apiToken.userTokenOptionsBtn") }}
                  </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </data-table>

      <v-dialog v-model="addEmployeeDialog" persistent max-width="500px">
        <v-card class="py-4">

          <v-card-title v-if="isEditDialog === true">
          <h3 class="primary--text mb-2">
                    {{ $t("profile.informations.contact") }}
                  </h3></v-card-title>
          <v-card-title v-else>{{ $t('users.dialogs.addNew.title') }}</v-card-title>
          <v-card-text>
                     <v-chip v-if="isEditDialog && profileChanges" label color="primary" class="mb-2" small>
                {{ $t("profile.informations.updated") }}
              </v-chip>
            <v-container>
              <v-form ref="addUserForm" v-model="addEmployeeFormValid" :disabled="loading">
                <v-row>
                  <v-col class="pa-0" cols="12">
                    <label for="name">{{ $t('users.dialogs.addNew.name') }}</label>
                    <v-text-field
                      v-model="newUser.name"
                      id="name"
                      outlined
                      dense
                      validate-on-blur
                      :rules="rules.userName"
                    ></v-text-field>
                    <label for="surname">{{ $t('users.dialogs.addNew.surname') }}</label>
                    <v-text-field
                      v-model="newUser.surname"
                      id="surname"
                      outlined
                      dense
                      validate-on-blur
                      :rules="rules.surname"
                    ></v-text-field>
                    <label for="phone">{{ $t('users.dialogs.addNew.phone') }}</label>
                    <v-text-field
                      v-model="newUser.phone"
                      id="phone"
                      outlined
                      dense
                      validate-on-blur
                      :rules="[...required, maxCharInPhoneNumber]"
                    ></v-text-field>
                    <label for="email">{{ $t('users.dialogs.addNew.email') }}</label>
                    <v-text-field
                      v-model="newUser.email"
                      id="email"
                      outlined
                      dense
                      validate-on-blur
                      :readonly="isEditDialog"
                      :rules="emailRules"
                    ></v-text-field>
                    <label  v-if="!isEditDialog" for="role">{{ $t('users.dialogs.addNew.userRole') }}</label>
                    <v-select  v-if="!isEditDialog"
                      v-model="newUser.role"
                      :items="roles"
                      id="role"
                      item-value="id"
                      item-text="description"
                      class="text-left"
                      outlined
                      dense
                      persistent-hint
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions class="px-8">
            <v-spacer></v-spacer>
            <v-btn
              color="secodary"
              outlined
              depressed
              class="mr-2"
              :disabled="loading"
              @click="closeAddEmployeeDialog"
            >
              {{ $t('common.cancel')}}
            </v-btn>
            <v-btn
              color="primary"
              depressed
              :loading="loading"
              :disabled="profileChanges || (!isEditDialog && !addEmployeeFormValid)"
              @click="addUser"
            >
              {{ isEditDialog ? $t('common.update') :  $t('common.save')}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="blockEmployeeDialog" persistent max-width="500px">
        <v-card class="py-4">
          <v-card-title>{{ $t('users.dialogs.blockUser.title')}}</v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="blockUserForm" v-model="blockEmployeeFormValid" :disabled="loading">
                <v-row>
                  <v-col class="pa-0" cols="12">
                    <label for="name">{{ $t('users.dialogs.blockUser.reason')}}</label>
                    <v-textarea
                      v-model="blockReason"
                      outlined
                      no-resize
                      validate-on-blur
                      :rows="3"
                      :rules="required"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions class="px-8">
            <v-spacer></v-spacer>
            <v-btn
              color="secodary"
              outlined
              depressed
              class="mr-2"
              :disabled="loading"
              @click="closeBlockEmployeeDialog"
            >
              {{ $t('common.cancel')}}
            </v-btn>
            <v-btn
              color="primary"
              depressed
              :loading="loading"
              :disabled="!blockEmployeeFormValid"
              @click="blockEmployee"
            >
              {{ $t('users.dialogs.blockUser.block')}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="setRoleDialog" persistent max-width="500px">
        <v-card class="py-4">
          <v-card-title>{{ $t('users.dialogs.setRole.title')}}</v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="setRoleForm" :disabled="loading">
                <v-row>
                  <v-col class="pa-0" cols="12">
                     <v-select
                        v-model="setRoleValue"
                        :items="roles"
                        item-value="id"
                        item-text="description"
                        class="text-left"
                        outlined
                        dense
                        persistent-hint
                      ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions class="px-8">
            <v-spacer></v-spacer>
            <v-btn
              color="secodary"
              outlined
              depressed
              class="mr-2"
              :disabled="loading"
              @click="closeSetRoleDialog"
            >
              {{ $t('common.cancel')}}
            </v-btn>
            <v-btn
              color="primary"
              depressed
              :loading="loading"
              @click="setRoleEmployee"
            >
              {{ $t('common.save')}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <Confirm ref="confirm" />

      <IntegrationApiTokenDialog
        :user="selectedUser"
        :isOpen="isIntegrationApiTokenDialogOpen"
        @close="isIntegrationApiTokenDialogOpen=false"
      />
      </div>
  </template>

<script>
import IntegrationApiTokenDialog from '@/views/auth/dialogs/IntegrationApiTokenDialog.vue'
import { getEnumDescription, maxCharInPhoneNumber } from '@/utils/helpers'
import Service from '@/services'
import { startConversation } from '@/signalr/chat'
import * as roleTypes from '@/types/role-types'
import Confirm from '@/components/dialogs/Confirm.vue'

export default {
  name: 'Employees',
  props: {
    isMyCompanyRoute: {
      type: Boolean,
      required: true
    },
    isIntegrationApiEnabled:
      {
        type: Boolean
      }
  },
  components: {
    Confirm,
    IntegrationApiTokenDialog
  },
  data () {
    return {
      loading: false,
      companyId: this.isMyCompanyRoute ? this.$store.getters.userCommonData.companyId : this.$route.params.id,
      employees: [],
      addEmployeeDialog: false,
      profileChanges: false,
      newUser: {
        name: '',
        surname: '',
        phone: '',
        email: '',
        userType: 1,
        role: roleTypes.employee
      },
      addEmployeeFormValid: true,
      required: [(v) => !!v.trim() || this.$t('validation.fieldRequired')],
      maxCharInPhoneNumber,
      emailRules: [
        (v) => !!v.trim() || this.$t('validation.emailRequired'),
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || this.$t('validation.emailInvalidFormat')
      ],
      rules: {
        userName: [
          v => (!!v && !!v.trim()) || this.$t('validation.fieldRequired'),
          v => v.length <= 15 || this.$t('validation.maxCountChar', { count: 15 }),
          v => {
            const regex = /^[A-Za-z żźćńółęąśŻŹĆĄŚĘŁÓŃ-]+$/
            const matches = regex.test(v)
            return matches || this.$t('validation.invalidField')
          }
        ],
        surname: [
          v => (!!v && !!v.trim()) || this.$t('validation.fieldRequired'),
          v => v.length <= 25 || this.$t('validation.maxCountChar', { count: 25 }),
          v => {
            const regex = /^[A-Za-z żźćńółęąśŻŹĆĄŚĘŁÓŃ-]+$/
            const matches = regex.test(v)
            return matches || this.$t('validation.invalidField')
          }
        ]
      },
      requiredSelect: [(v) => !!v || this.$t('validation.fieldRequired')],
      blockEmployeeDialog: false,
      setRoleDialog: false,
      setRoleEmployeeId: null,
      setRoleValue: null,
      blockReason: '',
      blockEmployeeFormValid: false,
      blockEmployeeId: null,
      userTypes: [
        {
          text: this.$t('users.dialogs.addNew.types.shipper'),
          value: 1
        },
        {
          text: this.$t('users.dialogs.addNew.types.ferryman'),
          value: 2
        },
        {
          text: this.$t('users.dialogs.addNew.types.shipperAndFerryman'),
          value: 3
        }
      ],
      searchInputValue: '',
      selectedUser: null,
      isEditDialog: false,
      isIntegrationApiTokenDialogOpen: false,
      isEditDataDialogOpen: false
    }
  },
  computed: {
    creationRoles () {
      return this.$store.getters.enums.roles.filter((el) => el.id === roleTypes.employee || el.id === roleTypes.driver)
    },
    roles () {
      if (this.$store.getters.isManager) {
        return this.$store.getters.enums.roles.filter((el) => el.id === roleTypes.employee || el.id === roleTypes.driver)
      } else {
        return this.$store.getters.enums.roles.filter((el) => el.id === roleTypes.employee || el.id === roleTypes.driver || el.id === roleTypes.manager)
      }
    },
    allRoles () {
      return this.$store.getters.enums.roles
    },
    userStatus () {
      return this.$store.getters.enums.userStatus
    },
    currentUserId () {
      return this.$store.getters.userCommonData.id
    },
    headers () {
      const result = [
        {
          text: this.$t('companiesDetails.userName'),
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: this.$t('companiesDetails.id'), sortable: false, value: 'displayId' },
        { text: this.$t('companiesDetails.email'), sortable: false, value: 'email' },
        { text: this.$t('companiesDetails.phone'), sortable: false, value: 'phone' }
      ]

      result.push({ text: this.$t('users.dialogs.addNew.userRole'), sortable: false, align: 'center', value: 'role' })
      result.push({ text: 'Status', sortable: false, align: 'center', value: 'status' })
      result.push({ text: '', align: 'end', width: '75', value: 'chat', sortable: false })

      if ((this.$store.getters.isOwner || this.$store.getters.isManager) && this.isMyCompanyRoute) {
        result.push({
          text: '',
          align: 'end',
          width: '75',
          value: 'actions',
          sortable: false
        })
      }

      return result
    }
  },
  methods: {
    closeIntegrationApiTokenDialog () {
      this.isIntegrationApiTokenDialogOpen = false
    },
    openIntegrationApiTokenDialog (user) {
      this.selectedUser = user
      this.isIntegrationApiTokenDialogOpen = true
    },
    async openEditDataDialog (user) {
      this.selectedUser = user
      this.newUser = user
      this.isEditDialog = true
      this.addEmployeeDialog = true
      const { data } = await Service.get('/User/GetCompanyUserProfile', {
        UserId: user.id
      })
      this.profileChanges = data.profileChanges != null
    },
    displayActionOptions (item) {
      return this.isMyCompanyRoute && (this.$store.getters.isOwner || this.$store.getters.isManager)
    },
    displayActionButton (item) {
      return this.isMyCompanyRoute && (this.$store.getters.isOwner || this.$store.getters.isManager)
    },
    getUserRole (role) {
      return getEnumDescription(this.allRoles, role)
    },
    getUserStatus (status) {
      return getEnumDescription(this.userStatus, status)
    },
    isCompanyOwner (item) {
      return item.role !== 1
    },
    isOwner (item) {
      return item.role === roleTypes.owner
    },
    isManager (item) {
      return item.role === roleTypes.manager
    },
    refresh () {
      this.$refs.Employees.getData()
    },
    startConversationWithUser (userId) {
      startConversation(userId)
    },
    openAddEmployeeDialog () {
      this.addEmployeeDialog = true
    },
    closeAddEmployeeDialog () {
      this.addEmployeeDialog = false
      this.isEditDialog = false
      this.profileChanges = false
      this.newUser = {
        name: '',
        surname: '',
        phone: '',
        email: '',
        role: 2
      }
      this.$refs.addUserForm.resetValidation()
    },
    async addUser () {
      this.loading = true
      try {
        if (this.isEditDialog) {
          await Service.post('/User/UpdateCompanyUserProfile', {
            userId: this.newUser.id,
            name: this.newUser.name,
            surname: this.newUser.surname,
            phone: this.newUser.phone
          })
        } else {
          await Service.post('Company/AddCompanyUser', this.newUser)
        }
        this.refresh()
      } catch (e) {
      } finally {
        this.loading = false
        this.closeAddEmployeeDialog()
      }
    },
    openSetRoleEmployeeDialog (item) {
      this.setRoleDialog = true
      this.setRoleEmployeeId = item.id
      this.setRoleValue = item.role
    },
    closeBlockEmployeeDialog () {
      this.blockEmployeeDialog = false
      this.blockReason = null
      this.blockEmployeeId = null
    },
    closeSetRoleDialog () {
      this.setRoleDialog = false
    },
    async blockEmployee () {
      this.loading = true
      try {
        await Service.post('CompanyUserManagement/Block', {
          userId: this.blockEmployeeId,
          blockReason: this.blockReason
        })
        await this.getDetails()
      } catch (e) {
      } finally {
        this.loading = false
        this.closeBlockEmployeeDialog()
      }
    },
    async setRoleEmployee () {
      this.loading = true
      try {
        await Service.post('CompanyUserManagement/SetRole', {
          userId: this.setRoleEmployeeId,
          role: this.setRoleValue
        })
        await this.getDetails()
      } catch (e) {
      } finally {
        this.loading = false
        this.closeSetRoleDialog()
      }
    },
    async activate (id) {
      this.loading = true

      if (await this.$refs.confirm.open(this.$t('users.deactivateTitle'), this.$t('users.deactivateDesc'))) {
        try {
          await Service.post('CompanyUserManagement/Activate', {
            userId: id
          })
          await this.getDetails()
          await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
        } catch (e) {}
      }
      this.loading = false
    },
    async deactivate (id) {
      this.loading = true

      if (await this.$refs.confirm.open(this.$t('users.activateTitle'), this.$t('users.activateDesc'))) {
        try {
          await Service.post('CompanyUserManagement/Deactivate', {
            userId: id
          })
          await this.getDetails()
          await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
        } catch (e) {}
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>
.stateChange {
  color: var(--v-stateChange);
}
.active {
  color: var(--v-primary);
}
</style>
