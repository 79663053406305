<template>
  <v-data-table
    :headers="headers"
    :items="documents"
    :loading="loading"
    :items-per-page="-1"
    hide-default-footer
  >
    <template v-slot:item.type="{item}">
      <span v-if="item.type === documentTypes.policy">{{ $t('companiesDetails.documents.policy') }}</span>
      <span v-else-if="item.type === documentTypes.licence">{{ $t('companiesDetails.documents.licence') }}</span>
      <span v-else-if="item.type === documentTypes.certificate">{{ $t('companiesDetails.documents.certificate') }}</span>
      <span v-else-if="item.type === documentTypes.other">{{ $t('companiesDetails.documents.other') }}</span>
    </template>
    <template v-slot:item.validTo="{item}">
      {{ item.validTo | dateFormat("DD.MM.YYYY") }}
    </template>
    <template v-slot:item.actions="{item}">
      <v-btn
        color="primary"
        outlined
        depressed
        @click="getFiles(item)"
      >
        {{ $t('companiesDetails.documents.download') }}
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import Service from '@/services'
import { downloadFile } from '@/utils/helpers'
export default {
  name: 'DocumentsTable',
  props: {
    documentTypes: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      documents: [],
      headers: [
        {
          text: this.$t('companiesDetails.name'),
          value: 'description',
          sortable: false
        },
        {
          text: this.$t('companiesDetails.type'),
          value: 'type',
          sortable: false,
          align: 'center'
        },
        {
          text: this.$t('companiesDetails.date'),
          value: 'validTo',
          sortable: false,
          align: 'center'
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'end',
          width: '75'
        }
      ]
    }
  },
  mounted () {
    this.getDocumentList()
  },
  methods: {
    async getDocumentList () {
      try {
        const { data } = await Service.get(`Company/GetCompanyDocuments?companyId=${this.$route.params.id}`)
        this.documents = data
      } catch (e) {
      }
    },
    async getFiles (item) {
      try {
        item.files.forEach(async file => {
          const { data } = await Service.getFile('/Company/GetCompanyDocumentFile', {
            companyId: this.$route.params.id,
            fileName: file.name
          })

          downloadFile(file, data)
        })
      } catch (e) {}
    }
  }
}
</script>
