<template>
  <v-dialog v-model="value" persistent width="450px">
    <v-card outlined>
      <v-card-title>

        <h4 class="primary--text">
        {{ $t('serverResponseDialog.title') }}
        </h4>

      </v-card-title>

      <v-card-text>
        <span>{{ $t('serverResponseDialog.statusCode') }} {{ httpResponse.statusCode }}</span>

        <div
          v-if="httpResponse.content"
          class="mt-2"
        >
          <label>{{ $t('serverResponseDialog.content') }}</label>
          <v-textarea
            :value="httpResponse.content"
            outlined
            dense
            readonly
          ></v-textarea>
        </div>

        <v-card-actions>

          <v-spacer></v-spacer>
          <v-btn color="primary" @click="close" depressed>
            {{ $t('common.close') }}
          </v-btn>

        </v-card-actions>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ServerResponseDialog',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    httpResponse: {
      type: Object,
      default: null
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
